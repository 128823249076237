<template>
  <div class="login-container">
    <!-- 登录区域 -->
    <div class="login-box">
      <!-- 头像 -->
      <div class="touxiang-box">
        <img src="https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/violet.jpg" alt="">
      </div>
      <!-- 登录表单 -->
      <el-form ref="loginFormRef" :rules="loginRules" :model="loginForm" label-width="0" class="login-form">
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input placeholder="请输入用户名" v-model="loginForm.username" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password" placeholder="请输入密码" prefix-icon="el-icon-lock"></el-input>
        </el-form-item>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button type="primary" size="mini" @click="btnLogin">立即登录</el-button>
          <el-button type="success" size="mini" @click="registerDialogVisible = true">注册</el-button>
          <el-button type="info" size="mini" @click="btnReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 用户注册的对话框 -->
    <!-- :visible.sync用户控制对话框的显示和隐藏 ， addDialogClosed为对话框关闭事件 -->
    <el-dialog title="注册" :visible.sync="registerDialogVisible" width="50%" @close="registerDialogClosed">
      <!-- :model绑定数据，:rules添加校验规则 -->
      <el-form :model="registerForm" :rules="registerUserRules" ref="registerFormRef" label-width="70px">
        <!-- 用户名 -->
        <el-form-item label="用户名" prop="username">
          <el-input v-model="registerForm.username"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码" prop="password">
          <el-input v-model="registerForm.password"></el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="registerForm.email"></el-input>
        </el-form-item>
        <!-- 电话 -->
        <el-form-item label="电话" prop="phone">
          <el-input v-model="registerForm.phone"></el-input>
        </el-form-item>
        <!-- 地址 -->
        <el-form-item label="地址" prop="address">
          <el-input v-model="registerForm.address"></el-input>
        </el-form-item>
        <!-- 性别 -->
        <el-form-item label="性别">
          <el-radio-group v-model="registerForm.sex">
            <el-radio label="true" >男</el-radio>
            <el-radio label="false" >女</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <!-- 表单下方的按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="registerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="registerUser">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
export default {
  name : 'Login' , 
  data(){
    return{
      // 表单数据对象
      loginForm:{
        username : "linwu" , 
        password : "111111" ,
      } , 
      // 表单验证对象
      loginRules : {
        // 校验用户名和密码
        username:[
          // required是否必填项  message提示信息   trigger触发时机 blur表示失去焦点
          { required: true, message: "用户名为必填项", trigger: "blur" },
          { min: 4, max: 12 , message: "长度在 5 到 12 个字符" , trigger: "blur"}
        ] , 
        password:[
          // required是否必填项  message提示信息   trigger触发时机 blur表示失去焦点
          { required: true, message: "密码为必填项", trigger: "blur" },
          { min: 4, max: 12 , message: "长度在 5 到 10 个字符" , trigger: "blur"}
        ]
      } , 
      registerDialogVisible : false, // 注册用户的对话框状态（打开和关闭） 
      registerForm:{      // 要添加的属性
        username:"" , 
        password:"" , 
        email:"" , 
        phone:"" , 
        address:"" , 
        sex:"" 
      } , 
      // 注册用户的表单验证
      registerUserRules:{
        username:[
          {required:true , message:"请输入用户名" , trigger:"blur"} , 
          {min:5 , max:12 , message:"长度在 5 到 12 个字符" , trigger:"blur"}
        ] , 
        password:[
          {required:true , message:"请输入密码" , trigger:"blur"} , 
          {min:5 , max:10 , message:"长度在 5 到 10 个字符" , trigger:"blur"}
        ] , 
        email:[
          {required:true , message:"请输入邮箱" , trigger:"blur"} , 
        ] , 
        phone:[
          {required:true , message:"请输入电话号码" , trigger:"blur"}
        ] , 
        phone:[
          {required:true , message:"请输入地址" , trigger:"blur"} 
        ] 
      } ,
    }
  } , 
  methods:{
    // 登录方法
    /*
      第一个if :先前端验证页面表单填写的信息是否符合要求，符合要求的话
      第二个if :再进行后端验证，如果表单填写内容和后台数据一致，则表示验证成功，可以登录
    */
    btnLogin(){
      // 1. validate属性，验证表单所填写的内容是否符合我们的要求 
      this.$refs.loginFormRef.validate(async (valid) => {
        if(!valid) return ;  // 如果验证失败，即所填写内容不符合验证规则，直接返回// 先验证表单所填内容是否通过
        // 2. 访问后台
          // const {data:res} = await this.$http.post("login" , this.loginForm) ;     // 验证成功，访问后台，从login接口获取数据
          // //  验证所填内容和接口数据是否吻合  吻合则跳转页面，并给出提示信息
          // if(res.flag == "ok"){  
          //   this.$message.success("登录成功，欢迎访问疫情信息管理系统！") ; 
          //   // 存储user信息，让浏览器记住登录的用户，将登录信息存储在session中
          //     window.sessionStorage.setItem("user", JSON.stringify(res.user)); 
          //     // 将登录用户的id保存在session中，以便确定该用户的权限
          //     window.sessionStorage.setItem("user_id", JSON.stringify(res.user_id)); 
          //     this.$router.push({path:"/home"}) ;  // 跳转页面到home
          //     console.log(res);
          //   // }
          // }else{  // 不吻合则登陆失败，给出提示信息
          //   this.$message.error("登录失败，请检查用户名或密码是否正确！") ; 
          // }
          const _this = this ;   // 在发送过请求之后，this就变成了请求的this，我们要使用前台全局的this需要预先获取
          // 发送请求
          console.log(this.loginForm);
          this.$axios.post('/login' , this.loginForm).then(res =>{
            console.log(res);
            const jwt = res.headers['authorization'] ; 
            const userInfo= res.data.data ; 

            // 将用户的登录信息共享到全局，以便获取
            _this.$store.commit("SET_TOKEN" , jwt) ; 
            _this.$store.commit("SET_USERINFO" , userInfo) ; 
            // 然后我们再登录，localStorage和sessionStorage中就会保存有相关的信息，我们可以在任意页面获取
            console.log(_this.$store.state.userInfo);

            this.$message.success("登陆成功！") ; 
            // 登录成功跳转到博客首页
            _this.$router.push("/manage") ; 
          }) ; 
      })
    } , 
    // 重置方法
    btnReset(){
      this.$refs.loginFormRef.resetFields() ; 
    } , 
    // 监听注册用户
    registerDialogClosed(){
      this.$refs.registerFormRef.resetFields() ; 
    } , 
    // 注册用户方法
    registerUser(){
      this.$message({
        type: 'warning',
        message: '暂未开放注册功能~'
      });    
      // this.$refs.registerFormRef.validate(async valid => {
      //   if(!valid) return ;  // 如果页面表单验证没有通过直接返回重试
      //   const {data:res} = await this.$http.post("addUser" , this.registerForm) ;  // 将前台数据返回到后台
      //   if(res != "success"){
      //     return this.$message.error("操作失败！");
      //   }else{
      //     this.$message.success("注册成功！");
      //     this.registerDialogVisible = false ;  // 将窗口隐藏
      //   }
      // }) ; 
    } , 
  }
}
</script>


<style scoped>  
/* 根节点样式 */
.login-container {
  background-color: #2b4b6b;
  height: 100%;
}
/* 登录框 */
.login-box {
  width: 450px;
  height: 300px;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(224, 224, 224);
}
/* 头像框 */
.touxiang-box {
  width: 100px;
  height: 100px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #eee;
  padding: 5px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px #ddd;
}
.touxiang-box > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ddd;
}

/* 表单样式 */
.login-form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: center;
}
</style>